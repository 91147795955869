import { Link, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'

import variables from '../../../../variables.module.scss'
import { LicenseEnterpriseEdition, LicenseOndemandEdition, LicenseProCompEdition, LicenseProEdition, LicenseProUnlEdition, LinkContactUs } from '../../../../consts'
import useLicense from '../../../../hooks/useLicense'

export const LicenseIssueText: React.FC = () => {
  const { licenseInfo: info, commercialEdition, nodeLimitOverflow, apiCapacityDepleted, expired, hubPublicNetworkOk } = useLicense()

  const defaultText: JSX.Element = <>
    <Typography variant='body1' fontFamily={variables.textFontFamily} color={variables.fontColor}>
      Oops! It seems something went wrong with your license, please <Link href={LinkContactUs} target='_blank' underline='hover' fontWeight={700} color={variables.blueColor}>contact us</Link> for a swift resolution.
    </Typography>
  </>

  const expirableEditions = [LicenseEnterpriseEdition, LicenseOndemandEdition, LicenseProCompEdition, LicenseProUnlEdition]

  const [issueText, setIssueText] = useState(defaultText)

  useEffect(() => {
    if ((info.doc.edition != LicenseEnterpriseEdition) && !hubPublicNetworkOk) {
      // NON-ENTERPRISE: no internet connection

      setIssueText(<>
        <Typography variant='body1' fontFamily={variables.textFontFamily} color={variables.fontColor}>
          Internet connectivity issues detected. Check the logs (Hub) for more details. Clusters that can not guarantee an active internet connection require an ENTERPRISE license. <Link href={LinkContactUs} target='_blank' underline='hover' fontWeight={700} color={variables.blueColor}>Contact us</Link> to get one for free.
        </Typography>
      </>)
    } else if (nodeLimitOverflow && !commercialEdition) {
      // COMMUNITY: node limit overflow
      setIssueText(<>
        <Typography variant='body1' fontFamily={variables.textFontFamily} color={variables.fontColor}>
          - Log in to use your license; or <br />
          - Sign up to activate your complimentary PRO license, free of charge, which supports large clusters with limited API capacity.
        </Typography>
      </>)
    } else if (info.doc.end<0) {
      // ENTERPRISE/ON-DEMAND: expired
      setIssueText(<>
        <Typography variant='body1' fontFamily={variables.textFontFamily} color={variables.fontColor}>
          - Your license is pending activation.<br />
          - Check your inbox for activation instructions. <br />
          - Please <Link href={LinkContactUs} target='_blank' underline='hover' fontWeight={700} color={variables.blueColor}>contact us</Link> if something is wrong.
        </Typography>
      </>)
    } else if (apiCapacityDepleted && ((info.doc.edition == LicenseProEdition) || (info.doc.edition == LicenseProCompEdition))) {
      // PRO: API capacity ran out
      setIssueText(<>
        <Typography variant='body1' fontFamily={variables.textFontFamily} color={variables.fontColor}>
          API call capacity limit reached!
        </Typography>
      </>)
    } else if (expirableEditions.includes(info.doc.edition) && expired) {
      // ENTERPRISE/ON-DEMAND: expired
      setIssueText(<>
        <Typography variant='body1' fontFamily={variables.textFontFamily} color={variables.fontColor}>
          - Your license has expired. <br />
          - Please <Link href={LinkContactUs} target='_blank' underline='hover' fontWeight={700} color={variables.blueColor}>contact us</Link> to extend your license.
        </Typography>
      </>)
    } else if (commercialEdition && nodeLimitOverflow && info.maxNodeCount === 0) {
      setIssueText(<>
        <Typography variant='body1' fontFamily={variables.textFontFamily} color={variables.fontColor}>
          Your license is invalid.<br /><br />
          Please <Link href={LinkContactUs} target='_blank' underline='hover' fontWeight={700} color={variables.blueColor}>contact us</Link> for a swift resolution.
        </Typography>
      </>)
    } else if (commercialEdition && nodeLimitOverflow) {
      // PRO: node limit overflow
      setIssueText(<>
        <Typography variant='body1' fontFamily={variables.textFontFamily} color={variables.fontColor}>
          Node limit reached!
        </Typography>
      </>)
    } else if (info.doc.edition === "") {
      setIssueText(<>
        <Typography variant='body1' fontFamily={variables.textFontFamily} color={variables.fontColor}>
          There is a failure to read essential license information such as: edition, node limit or expiry date.<br /><br />
          Please <Link href={LinkContactUs} target='_blank' underline='hover' fontWeight={700} color={variables.blueColor}>contact us</Link> for a swift resolution.
        </Typography>
      </>)
    }
  }, [info, commercialEdition, nodeLimitOverflow, apiCapacityDepleted, expired])

  return issueText
}
